<template>
  <div class="main"
       ref="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <Button type="text"
                slot="extra"
                @click.stop="gridRoadAttributeListVisible=true">设置属性>></Button>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex == 0">
              <Select v-model="gridType"
                      placeholder="网格类型"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in gridTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <Input v-model="name"
                   placeholder="网格名称"
                   maxlength="30"
                   clearable
                   v-stringLimit
                   class="m-r-10"
                   style="width:160px" />
            <!-- <template v-if="currentTabIndex == 1">
              <Select v-model="gridType"
                      placeholder="绑定对象"
                      clearable
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in gridTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template> -->
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/grid/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/grid/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <div>
          <Button type="primary"
                  class="m-r-10"
                  v-if="checkPermi(['/admin/grid/add'])"
                  @click.stop="onClickAdd">
            <Icon custom="i-icon icon-tianjia1"
                  size="16"></Icon>
            添加
          </Button>
        </div>
        <Button type="text"
                @click.stop="onClickComplete">完成度设置>></Button>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <Table ref="table"
                 :width="tableWidth"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 :row-class-name="rowClassName"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <template v-if="currentTabIndex===0">
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/grid/edit']"
                        @click.stop="onClickEditGrid(row)">修改</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/grid/edit']"
                        @click.stop="onClickStatusGrid(row)">{{row.isEnable==1?'禁用':'恢复'}}</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/grid/delete']"
                        @click.stop="onClickDeleteGrid(row)">删除</Button>
              </template>
              <template v-if="currentTabIndex===1">
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/grid/edit']"
                        @click.stop="onClickEditEnclosure(row)">修改</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/grid/edit']"
                        @click.stop="onClickStatusEnclosure(row)">{{row.isEnable==1?'禁用':'恢复'}}</Button>
                <Button type="text"
                        class="tableOperaBtn"
                        v-hasPermi="['/admin/grid/delete']"
                        @click.stop="onClickDeleteEnclosure(row)">删除</Button>
              </template>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
    <!-- 新增 / 修改网格 -->
    <GridModal v-model="gridVisible"
               :width="drawerWidth"
               :dataId.sync="gridId"
               @onClickConfirm="getList"
               @onChange='onChangClearRowStyle'></GridModal>

    <!-- 网格详情 -->
    <GridDetailModal v-model="gridDetailVisible"
                     :width="drawerWidth"
                     :dataId.sync="gridDetailId"
                     @onChange='onChangClearRowStyle'></GridDetailModal>

    <!-- 绑定对象 -->
    <GridBindObjectModal v-model="gridBindObjectVisible"
                         :item.sync="gridBindObject"
                         @onClickConfirm="getList"
                         @onChange='onChangClearRowStyle'></GridBindObjectModal>

    <!-- 完成度设置 -->
    <GridFinishDegreeModal v-model="gridCompleteVisible"
                           :type.sync="gridCompleteType"></GridFinishDegreeModal>

    <!-- 网格等级列表 -->
    <GridRoadAttributeListModal v-model="gridRoadAttributeListVisible"
                                @onClickConfirm="getList"></GridRoadAttributeListModal>

    <!-- 新增 / 修改电子围栏 -->
    <ElectronicFenceModal v-model="electronicFenceVisible"
                          :width="drawerWidth"
                          :dataId.sync="electronicFenceId"
                          :itemIds.sync="electronicFenceItemIds"
                          @onClickConfirm="getList"></ElectronicFenceModal>
    <!-- 绑定对象 -->
    <ElectronicBindObjectModal v-model="electronicBindObjectVisble"
                               :item.sync="electronicBindObject"
                               @onClickConfirm="getList"
                               @onChange='onChangClearRowStyle'></ElectronicBindObjectModal>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTree from '@/components/common/MyTree';
import GridModal from '@/components/product/admin/modal/work/GridModal'
import GridDetailModal from '@/components/product/admin/modal/work/GridDetailModal'
import GridBindObjectModal from '@/components/product/admin/modal/work/GridBindObjectModal'
import GridFinishDegreeModal from '@/components/product/admin/modal/work/GridFinishDegreeModal'
import GridRoadAttributeListModal from '@/components/product/admin/modal/work/GridRoadAttributeListModal'
import ElectronicFenceModal from '@/components/product/admin/modal/work/ElectronicFenceModal'
import ElectronicBindObjectModal from '@/components/product/admin/modal/work/ElectronicBindObjectModal'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    MyTree,
    GridModal,
    GridDetailModal,
    GridBindObjectModal,
    GridFinishDegreeModal,
    GridRoadAttributeListModal,
    ElectronicFenceModal,
    ElectronicBindObjectModal
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '作业网格'
        },
        {
          name: '电子围栏'
        },
        // {
        //   name: '重点路段'
        // },
      ],
      gridType: '',
      gridTypeArr: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '区域'
        },
        {
          id: 2,
          name: '线路'
        },
        {
          id: 3,
          name: '路段'
        },
      ],
      name: '',
      departId: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsWork: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '网格名称',
          key: 'name',
          minWidth: 200,
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickGridDetail(row)
                }
              }
            }, row.name);
          }
        },
        {
          title: '所属机构',
          key: 'depName',
          width: 200,
          tooltip: true,
          align: 'center'
        },
        {
          title: '网格类别',
          key: 'type',
          width: 100,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text = row.type === 1 ? '区域' : row.type === 2 ? '线路' : '路段'
            return h('span', text);
          }
        },
        {
          title: '道路属性',
          key: 'levelName',
          width: 100,
          tooltip: true,
          align: 'center'
        },
        {
          title: '规划里程(米)',
          key: 'km',
          tooltip: true,
          width: 110,
          align: 'center'
        },
        {
          title: '面积(平方米)',
          key: 'area',
          tooltip: true,
          width: 110,
          align: 'center'
        },
        {
          title: '绑定设施',
          key: 'bindObj',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let arr = row.bindObj ? row.bindObj.split(',').filter(item => item) : []
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              class: {
                line1: true
              },
              on: {
                click: () => {
                  this.onClickBindObject(row)
                }
              }
            }, row.bindObj ? arr.length : '点击绑定');
          }
        },
        // {
        //   title: '绑定车',
        //   key: 'managePhone',
        //   tooltip: true,
        //   width: 70,
        //   align: 'center'
        // },
        // {
        //   title: '绑定人',
        //   key: 'managePhone',
        //   tooltip: true,
        //   width: 70,
        //   align: 'center'
        // },
        {
          title: '启用状态',
          key: 'isEnable',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsElectronic: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '网格名称',
          key: 'name',
          tooltip: true,
          minWidth: 180,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          width: 180,
          align: 'center'
        },
        // {
        //   title: '网格类别',
        //   key: 'managePhone',
        //   tooltip: true,
        //   width: 120,
        //   align: 'center'
        // },
        // {
        //   title: '绑定对象',
        //   key: 'managePhone',
        //   tooltip: true,
        //   width: 120,
        //   align: 'center',
        //   render: (h, params) => {
        //     let row = params.row
        //     return h('span', {
        //       style: {
        //         color: '#25bb96',
        //         cursor: 'pointer'
        //       },
        //       class: {
        //         line1: true
        //       },
        //       on: {
        //         click: () => {
        //           this.onClickBindObject1(row, true)
        //         }
        //       }
        //     }, row.itemIdList ? row.itemIdList.length : '点击绑定');
        //   }
        // },
        {
          title: '面积(平方公里)',
          key: 'area',
          tooltip: true,
          width: 120,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'isEnable',
          tooltip: true,
          width: 90,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsEmphasis: [
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '网格名称',
          key: 'manageName',
          tooltip: true,
          width: 200,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'managePhone',
          tooltip: true,
          width: 120,
          align: 'center'
        },
        {
          title: '网格类别',
          key: 'managePhone',
          tooltip: true,
          width: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      drawerWidth: 0,
      // 新增 / 修改网格
      gridVisible: false,
      gridId: '',
      // 网格详情
      gridDetailVisible: false,
      gridDetailId: '',
      // 绑定对象
      gridBindObjectVisible: false,
      gridBindObject: null,
      // 电子围栏绑定对象
      electronicBindObjectVisble: false,
      electronicBindObject: null,
      // 完成度设置
      gridCompleteVisible: false,
      gridCompleteType: null,
      // 网格等级列表
      gridRoadAttributeListVisible: false,
      // 电子围栏
      electronicFenceVisible: false,
      electronicFenceId: '',
      electronicFenceItemIds: ''
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.gridId == items.gridId
            })
            this.selectVisitedIndexArr.push(data.gridId)
          } else {
            let data = this.data.find((items, index) => {
              return item.name == items.name
            })
            this.selectVisitedIndexArr.push(data.name)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsWork
          break;
        case 1:
          columns = this.columnsElectronic
          break;
        case 2:
          columns = this.columnsEmphasis
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateDepartmentTreeList',
    ]),
    init () {
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      this.updateDepartmentTreeList()
      this.getList()
    },
    // 添加
    onClickAdd () {
      switch (this.currentTabIndex) {
        case 0:
          this.gridVisible = true
          break;
        case 1:
          this.electronicFenceVisible = true
          break;
        default:
          break;
      }
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.departId = data.id
      this.onClickSearch()
    },
    // 点击设置完成度
    onClickComplete () {
      this.gridCompleteVisible = true
      this.gridCompleteType = 1
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.currentTabIndex === 0) {
        if (this.selectVisitedIndexArr.includes(row.gridId)) {
          return 'table-bgcolor'
        }

      } else {
        if (this.selectVisitedIndexArr.includes(row.name)) {
          return 'table-bgcolor'
        }
      }
      if (this.selectIndexArr.includes(row.gridId)) {
        return 'table-bgcolor'
      }
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.selectArr = []
      this.gridType = ''
      this.name = ''
      this.departId = ''
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []

    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          this.getGridList()
          break;
        case 1:
          this.getEnclosureList()
          break;
        case 2:
          this.getAlarmList()
          break;
        default:
          break;
      }
    },
    // 获取网格列表
    getGridList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        type: '',
        name: this.name,
        depid: this.departId
      }
      if (this.gridType) params.type = this.gridType
      this.tableLoading = true
      this.$http.getGridList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.grids
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击网格详情
    onClickGridDetail (row) {
      this.selectVisitedArr.push(row)
      this.gridDetailId = row.gridId
      this.gridDetailVisible = true
    },
    // 点击修改网格
    onClickEditGrid (row) {
      this.selectVisitedArr.push(row)
      this.gridId = row.gridId
      this.gridVisible = true
    },
    // 点击绑定对象
    onClickBindObject (row, boo = false) {
      this.selectVisitedArr.push(row)
      if (boo) {
        this.electronicBindObject = row
        this.electronicBindObjectVisble = true
      } else {
        this.gridBindObject = row
        this.gridBindObjectVisible = true
      }
    },
    onClickBindObject1 (row, boo = false) {
      this.selectVisitedArr.push(row)
      this.electronicBindObject = row
      this.electronicBindObjectVisble = true
    },
    // 点击删除网格
    onClickDeleteGrid (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            gridIds: row.gridId
          }
          this.$http.deleteGrid(params).then(res => {
            if (res.code === 200) {
              this.onChangClearRowStyle()
              if (res.result.message) {
                this.$Message.info(res.result.message)
              } else {
                this.$store.dispatch('updateGridTreeList', true)
                this.$Message.info(res.message)
                this.getList()
                this.$store.dispatch('updateGridTreeEnableList', true)
              }
            }
          })

        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改网格状态
    onClickStatusGrid (row) {
      this.selectVisitedArr.push(row)
      if (row.isEnable === 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.gridId,
              status: 0,
              name: row.name,
              gridType: 0,
            }
            this.$store.dispatch('createOrEditGrid', params).then(res => {
              this.$store.dispatch('updateGridTreeEnableList', true)
              this.getList()

            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.gridId,
              status: 1,
              name: row.name,
              gridType: 0,
            }
            this.$store.dispatch('createOrEditGrid', params).then(res => {
              this.$store.dispatch('updateGridTreeEnableList', true)
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
    onClickEditEnclosure (row) {
      this.electronicFenceId = row.id
      this.electronicFenceVisible = true
      // this.electronicFenceItemIds = row.itemIdList.join()
    },
    // 获取围栏列表
    getEnclosureList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        // type: '',
        name: this.name,
        itemIdList: [],
        depid: this.departId,
      }
      this.tableLoading = true
      this.$http.getEnclosureList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.gridMXVOList
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    onClickDeleteEnclosure (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            gridIds: row.id
          }
          this.$http.deleteGrid(params).then(res => {
            if (res.code === 200) {
              this.onChangClearRowStyle()
              if (res.result.message) {
                this.$Message.info(res.result.message)
              } else {
                this.$Message.info(res.message)
                this.getList()
              }
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改网格状态
    onClickStatusEnclosure (row) {
      this.selectVisitedArr.push(row)
      if (row.isEnable === 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0,
              name: row.name,
              gridType: 1,
            }
            this.$store.dispatch('createOrEditGrid', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1,
              name: row.name,
              gridType: 1,
            }
            this.$store.dispatch('createOrEditGrid', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
          onCancel: () => {
            this.onChangClearRowStyle()
          },
        })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>