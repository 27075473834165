<template>
  <Drawer :title="title"
          :mask="false"
          closable
          :width="width"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="drawer"
         v-if="data">
      <div class="drawer-cont">
        <div class="drawer-cont-left modal-cont"
             :style="{height:height+'px'}">
          <div class="section-title">
            <span>基础信息</span>
          </div>
          <ul class="form-ul">
            <li class="form-ul-li">
              <p class="li-title">网格名称：</p>
              <span>{{data.name || '--'}}</span>
            </li>
            <li class="form-ul-li">
              <p class="li-title">所属机构：</p>
              <span>{{data.depart || '--'}}</span>
            </li>
            <li class="form-ul-li">
              <p class="li-title">启用状态：</p>
              <span>{{data.status || '--'}}</span>
            </li>
            <!-- <li class="form-ul-li">
              <p class="li-title">负责人：</p>
              <span>{{principal}}</span>
            </li> -->
            <li class="form-ul-li">
              <p class="li-title">网格类型：</p>
              <span>{{data.type || '--'}}</span>
            </li>
            <!-- <li class="form-ul-li">
              <p class="li-title">描边宽度：</p>
              <span>{{width}}</span>
            </li> -->
            <!-- 网格类型为线路时显示 -->
            <li class="form-ul-li"
                v-show="data.type === '线路'">
              <p class="li-title">路段：</p>
              <span>{{data.section || '--'}}</span>
            </li>
            <!-- <li class="form-ul-li">
              <p class="li-title">颜色：</p>
              <ul class="li-color">
                <li v-for="(item,index) in colorArr"
                    :key="item.id"
                    @click.stop="onClickColor(index)"
                    :class="{current:colorIndex == index}">
                  <span></span>
                </li>
              </ul>
            </li> -->
            <li class="form-ul-li">
              <p class="li-title">面积：</p>
              <span>{{data.area || '--'}}</span>
              <span class="li-unit"
                    v-if="data.area">平方米</span>
            </li>
          </ul>
          <!-- 网格类型为路段时显示 -->
          <div class="section-title"
               v-if="data.type === '路段'">
            <span>检测信息</span>
          </div>
          <ul class="form-ul"
              v-if="data.type === '路段'">
            <li class="form-ul-li">
              <p class="li-title">线宽：</p>
              <span>{{data.lineWidth || '--'}}</span>
              <span class="li-unit"
                    v-if="data.lineWidth">米</span>
            </li>
          </ul>
          <div class="section-title">
            <span>网格属性</span>
          </div>
          <ul class="form-ul">
            <!-- 网格类型为路段时显示 -->
            <li class="form-ul-li"
                v-if="data.type === '路段'">
              <p class="li-title">道路属性：</p>
              <span>{{data.grade || '--'}}</span>
            </li>
            <!-- 网格类型为路段时显示 -->
            <li class="form-ul-li"
                v-if="data.type === '路段'">
              <p class="li-title">上级网格：</p>
              <span>{{data.superior || '--'}}</span>
            </li>
            <li class="form-ul-li"
                v-if="data.type === '路段'">
              <p class="li-title">设置为路牌：</p>
              <span>{{data.guideboard || '--'}}</span>
            </li>
            <li class="form-ul-li">
              <p class="li-title">规划里程：</p>
              <span>{{data.mileage || '--'}}</span>
              <span class="li-unit"
                    v-if="data.mileage">米</span>
            </li>
          </ul>
        </div>
        <div class="drawer-cont-right">
          <div id="gridDetailModalMapDiv"
               class="mapDiv"
               ref="gridDetailModalMapDiv">
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '@/utils/tMap'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '网格详情'
    },
    width: Number,
    dataId: String
  },
  data () {
    return {
      height: 0,
      data: {},
      map: null,  // 天地图实例
      zoom: 14, // 初始地图缩放等级
      config: {
        color: "#0079fe",
        weight: 3,
        opacity: 0.8,
        fillColor: "#0079fe",
        fillOpacity: 0.3
      },
      sectionArr: []
    };
  },
  watch: {
    visible (newVal) {
      newVal && this.init()
    },
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'mapCenterLnglat': 'getMapCenterLnglat',
    }),
  },
  mounted () {
    this.height = window.innerHeight - 160
    this.initData()
  },
  methods: {
    init () {
      if (!this.map) this.createMap()
    },
    initData () {
      this.$http.getGridTreeList({ type: 3 }).then(res => {
        if (res.code === 200) {
          this.sectionArr = res.result
        }
      })
    },
    getDetail () {
      this.$store.dispatch('getGridDetail', { gridId: this.dataId }).then(res => {
        this.data = res
        let viewport
        let overlay
        switch (res.drawType) {
          case 3:
            // 圆形
            this.data.points = new T.LngLat(res.lng, res.lat)
            overlay = new T.Circle(this.data.points, res.radius, this.config)
            this.map.addOverLay(overlay)
            // viewport = this.map.getViewport([this.data.points])
            // this.map.panTo(viewport.center, viewport.zoom)
            let bounds = overlay.getBounds()
            viewport = this.map.getViewport([bounds.Lq, bounds.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          case 4:
            // 矩形
            this.data.points = JSON.parse(res.points)
            this.map.addOverLay(new T.Rectangle([this.data.points.Lq, this.data.points.kq], this.config))
            viewport = this.map.getViewport([this.data.points.Lq, this.data.points.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          case 5:
            // 多边形
            this.data.points = JSON.parse(res.points)
            overlay = new T.Polygon(this.data.points, this.config)
            this.map.addOverLay(overlay)
            let lnglatList = [];
            this.data.points.forEach(pList => {
              pList.forEach(p => {
                let lnglat = new T.LngLat(p.lng, p.lat)
                lnglatList.push(lnglat)
              })
            })
            viewport = this.map.getViewport(lnglatList)
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          case 6:
            // 线
            this.data.points = JSON.parse(res.points)
            this.map.addOverLay(new T.Polyline(this.data.points, this.config))
            viewport = this.map.getViewport(this.data.points)
            this.map.panTo(viewport.center, viewport.zoom)
            break;
          default:
            // 多路段组成的路线 - 画网格
            let sectionIdList = res.sectionId.split(",")
            let selectSectionList = this.getSelectSectionList(sectionIdList, this.sectionArr)
            let totalPoints = []
            selectSectionList.forEach(section => {
              let points = JSON.parse(section.lnglat)
              this.overlay = new T.Polyline(points, this.config)
              this.map.addOverLay(this.overlay)
              points.forEach(p => {
                totalPoints.push(p)
              })
            })
            let viewport = this.map.getViewport(totalPoints)
            this.map.panTo(viewport.center, viewport.zoom)
            break;
        }
        switch (res.type) {
          case 1:
            this.data.type = '区域'
            break;
          case 2:
            this.data.type = '线路'
            break;
          case 3:
            this.data.type = '路段'
            break;
          default:
            break;
        }
        this.data.status = res.status === 1 ? '启用' : '禁用'
        this.data.guideboard = res.guideboard === 1 ? '是' : '否'
      })
    },
    getSelectSectionList (sectionIdList, sectionArr) {
      let result = []
      sectionArr.forEach(item => {
        if (sectionIdList.includes(item.id)) {
          let resultItem = { km: item.km, area: item.area, lnglat: item.lnglat }
          result.push(resultItem)
        }
        if (item.children && item.children.length > 0) {
          this.getSelectSectionList(sectionIdList, item.children).forEach(i => {
            result.push(i)
          })
        }
      })
      return result;
    },
    // 生成地图
    createMap () {
      tMap.init().then((T) => {
        this.map = new T.Map('gridDetailModalMapDiv')
        let lnglat
        if (this.mapCenterLnglat) {
          let mapCenterLnglat = this.mapCenterLnglat.split(',')
          lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
        } else {
          lnglat = new T.LngLat(119.130974, 36.706688)
        }
        this.map.centerAndZoom(lnglat, this.zoom)
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败,请刷新浏览器')
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('onChange', false)
      }
    },
    resetData () {
      this.data = {}
      this.map.clearOverLays()
    }
  },
}
</script>

<style lang='scss' scoped>
::v-deep {
  .ivu-drawer {
    top: 60px;
    bottom: 0;
    .ivu-drawer-body {
      padding: 0;
    }
  }
}
.form-ul-li {
  span {
    line-height: 32px;
  }
}
.drawer {
  position: relative;
  height: 100%;
  .drawer-cont {
    display: flex;
    height: 100%;
    .drawer-cont-left {
      width: 480px;
      height: 100%;
      max-height: 100%;
      padding: 0 10px;
      .li-color {
        display: flex;
        align-items: center;
        li {
          width: 22px;
          height: 22px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            width: 15px;
            height: 15px;
            cursor: pointer;
            display: flex;
          }
        }
        li:nth-child(1) span {
          border: 1px solid #1f86ea;
          background: rgba(31, 134, 234, 0.2);
        }
        li:nth-child(2) span {
          border: 1px solid #129b3a;
          background: rgba(18, 155, 58, 0.2);
        }
        li:nth-child(3) span {
          border: 1px solid rgb(244, 147, 58);
          background: rgba(244, 147, 58, 0.2);
        }
        li:nth-child(4) span {
          border: 1px solid rgb(71, 85, 238);
          background: rgba(71, 85, 238, 0.2);
        }
        li:nth-child(5) span {
          border: 1px solid rgb(244, 57, 57);
          background: rgba(244, 57, 57, 0.2);
        }
        .current {
          background: #bbdaf9;
        }
      }
    }
    .drawer-cont-right {
      flex: 1;
      .mapDiv {
        width: 100%;
        height: 100%;
      }
    }
  }
  .drawer-footer {
    line-height: 50px;
    background: #ffffff;
    border-top: 1px solid #e1e3e5;
    text-align: right;
    padding: 0 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    button {
      margin-left: 6px;
    }
  }
}
</style>