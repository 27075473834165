<template>
  <Modal :value="visible"
         :title="title"
         width="740"
         footer-hide
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="equipment">
      <div class="equipment-filtrate">
        <Select v-model="bigType"
                placeholder="大类"
                clearable
                @on-change="onChangeBigType"
                class="m-r-5"
                style="width:170px">
          <Option v-for="item in bigTypeArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="smallType"
                placeholder="小类"
                clearable
                @on-change="onChangeSmallType"
                class="m-r-5"
                style="width:170px">
          <Option v-for="item in smallTypeArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="status"
                placeholder="是否绑定"
                @on-change="onChangeStatus"
                class="m-r-5"
                style="width:170px">
          <Option v-for="item in statusArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <MyTreeSelect :value="name"
                      placeholder="对象名称"
                      clearable
                      :data="nameArr"
                      search
                      checked
                      @onCheckChange="onChangeName"
                      class="m-r-10"
                      style="width:170px">
        </MyTreeSelect>
      </div>
      <Table ref="table"
             :columns="getColumns"
             :data="data"
             height="320"
             border
             stripe
             :loading="tableLoading"
             @on-selection-change="onChangeSelect">
      </Table>
      <Page class="m-t-10 m-l-10"
            :total="totalPage"
            :current="pageNum"
            :page-size="pageSize"
            show-total
            show-sizer
            show-elevator
            @on-change="onPageChange"
            @on-page-size-change="onPageSizeChange" />
      <Button class="m-t-10 m-l-10"
              :disabled="selectArr.length===0"
              :loading="loading"
              @click.stop="onClickBind">{{this.status===1?'绑定':'解绑'}}</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect'
export default {
  name: 'equipmentBindingModal',
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '绑定对象'
    },
    item: null
  },
  data () {
    return {
      bigTypeArr: [{
        name: '车辆',
        id: 0
      }, {
        name: '人员',
        id: 1
      }],
      bigType: 0,
      smallType: '',
      smallTypeArr: [],
      status: '',
      statusArr: [
        {
          id: 1,
          name: '未绑定'
        },
        {
          id: 2,
          name: '已绑定'
        },
      ],
      nameId: '',
      name: '',
      nameArr: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
      columnsCar: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          key: 'carnumber',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          key: 'carTypeName',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定状态',
          key: 'bindstatus',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text = row.gridBindList.length > 0 ? '已绑定' : '未绑定'
            return h('span', text);
          }
        },
        {
          title: '所属机构',
          key: 'cardeptname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
      ],
      columnsPerson: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'name',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '人员类型',
          key: 'worktypename',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定状态',
          key: 'grids',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text = row.gridBindList.length > 0 ? '已绑定' : '未绑定'
            return h('span', text);
          }
        },
        {
          title: '所属机构',
          key: 'empldeptname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      loading: false
    }
  },
  watch: {
    item: {
      handler (newVal) {
        if (newVal) {
          if (newVal.itemIdList) {
            this.status = 2
          } else {
            this.status = 1
          }
          this.onChangeBigType()
        }
      },
      deep: true
    },
    name (newVal) {
      if (!newVal) {
        this.onClickSearch()
      }
    },
  },
  computed: {
    ...mapGetters({
      'carTypeArr': 'getCarTypeAllList',
      'carTreeArr': 'getCarTreeList',
      'personTreeArr': 'getPersonTreeList',
    }),
    workTypeArr () {
      let arr = []
      return arr.concat(this.$store.getters.getWorkTypeAllList)
    },
    getColumns () {
      let columns = []
      switch (this.bigType) {
        case 0:
          columns = this.columnsCar
          break;
        case 1:
          columns = this.columnsPerson
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarTreeList',
      'updatePersonTreeList',
      'updateWorkTypeAllList'
    ]),
    init () {
      this.updateCarTypeAllList()
      this.updateCarTreeList()
      this.updatePersonTreeList()
      this.updateWorkTypeAllList()
    },
    // 修改大类
    onChangeBigType (value) {
      if (value) {
        if (value == 0) {
          this.smallTypeArr = this.carTypeArr
          this.nameArr = this.carTreeArr
        } else {
          this.smallTypeArr = this.workTypeArr
          this.nameArr = this.personTreeArr
        }
      } else {
        this.smallTypeArr = this.carTypeArr
        this.nameArr = this.carTreeArr
      }
      this.smallType = ''
      this.name = ''
      this.onClickSearch()
    },
    onChangeSmallType () {
      this.onClickSearch()
    },
    onChangeStatus () {
      this.onClickSearch()
    },
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type !== 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.nameId = idArr.join()
      this.name = valueArr.join()
      this.onClickSearch()
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.selectArr = []
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.selectArr = []
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.selectArr = []
      this.getList()
    },
    getList () {
      switch (this.bigType) {
        case 0:
          this.getCarList()
          break;
        case 1:
          this.getPersonList()
          break;
        default:
          break;
      }
    },
    getCarList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        carCategory: '',
        carCategoryItem: '',
        carTypeId: this.smallType,
        carAttribution: '',
        carNumber: this.name,
        driveModel: '',
        status: '',
        carDeptId: '',
        // 1未绑 2 已绑
        bindingStatus: this.status,
        gridId: this.item.id
      }
      this.tableLoading = true
      this.$http.getCarList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    getPersonList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        status: '',
        employeeName: this.name,
        employeeNumber: '',
        phoneNumber: '',
        deptId: '',
        workType: this.smallType,
        // 1未绑 2 已绑
        bindingStatus: this.status,
        gridId: this.item.id
      }
      // if (this.smallType) params.worktype = this.smallType
      this.tableLoading = true
      this.$http.getPersonList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击绑定
    onClickBind () {
      if (this.status === 1) {
        // let check = {
        //   gridIdList: [this.item.id],
        //   itemIdList: this.selectArr,
        //   flag: true,
        // }
        // this.$http.checkEnclosurePersonOrCar(check).then((res) => {
        //   if (res.code === 200) {
        //     if (res.result.length == 0) {
        // 绑定
        let params = {
          gridIdList: [this.item.id],
          // name: this.item.name,
          // gridType: 1,
          itemIdList: this.selectArr,
          carAndEmpType: this.bigType == 0 ? 'car' : 'emp',
        }
        this.$http.boundEnclosurePersonOrCar2(params).then((res) => {
          this.getList()
          // console.log
          this.loading = false
          this.$emit('onClickConfirm')
        }).catch((err) => {

        });
        // } else {
        //   this.$Modal.confirm({
        //     title: '警告',
        //     content: res.result.join() + ' 监控时间重叠，请重新选择',
        //     onOk: () => {
        //     }
        //   })
        // }
        // }
        // })
      } else {
        let params = {
          gridIdList: [this.item.id],
          itemIdList: this.selectArr,
        }
        this.$Modal.confirm({
          title: '解绑',
          content: '确认解绑?',
          onOk: () => {
            this.loading = true
            this.$http.unboundEnclosurePersonOrCar(params).then((res) => {
              this.loading = false
              this.getList()
              this.$emit('onClickConfirm')
            }).catch(err => {
              this.loading = false
            })
          }
        })
      }
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:item', null)
      }
    },
    resetData () {
      this.selectArr = []
      this.bigType = 0
      this.smallType = ''
      this.nameId = ''
      this.name = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment {
  .equipment-cont {
    background: #f2f2f2;
    padding: 10px;
    margin: 10px 0;
    overflow-y: scroll;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #353639;
    }
    .binding {
      padding: 4px !important;
      background-color: #fff;
      border: 1px solid #dddddd;
      margin: 11px 5px 11px 0;
      height: 22px;
      .binding-icon {
        cursor: pointer;
      }
    }
  }
  .equipment-filtrate {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
