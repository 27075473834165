<template>
  <Drawer :title="dataId ? '修改' + title : '新增' + title"
          :mask="false"
          closable
          :width="width"
          :value="visible"
          @on-visible-change="onChangeVisible"
          :class="{'map-grid':sourceType == 'map'}">
    <div class="drawer">
      <div class="drawer-cont">
        <div class="drawer-cont-left modal-cont"
             :style="{height:height+'px'}">
          <div class="section-title">
            <span>基础信息</span>
          </div>
          <ul class="form-ul">
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>网格名称：</p>
              <Input v-model="name"
                     placeholder="网格名称"
                     maxlength="30"
                     v-stringLimit
                     clearable
                     style="width:300px"></Input>
            </li>
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>所属机构：</p>
              <MyTreeSelect ref="deptTreeRef"
                            :value="depart"
                            placeholder="所属机构"
                            :data="departmentTreeArr"
                            clearable
                            search
                            @onSelectChange="onChangeDepartment"
                            style="width:300px">
              </MyTreeSelect>
            </li>
            <!-- <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>网格类型：</p>
              <Select v-model="type"
                      placeholder="网格类型"
                      style="width:300px"
                      @on-change="onChangeType">
                <Option :value="item.id"
                        v-for="item in typeArr"
                        :key="item.id">{{item.name}}
                </Option>
              </Select>
            </li> -->
            <!-- 网格类型为线路时显示 -->
            <li class="form-ul-li"
                v-show="type===2">
              <p class="li-title"><span class="li-must">*</span>路段：</p>
              <MyTreeSelect :value="section"
                            placeholder="路段"
                            :data="sectionArr"
                            search
                            checked
                            @onCheckChange="onChangeSection"
                            style="width:300px">
              </MyTreeSelect>
            </li>
            <li class="form-ul-li">
              <p class="li-title">面积：</p>
              <Input v-model="area"
                     placeholder="面积"
                     maxlength="30"
                     v-floatLimit
                     clearable
                     class="m-r-5"
                     style="width:300px"></Input><span class="li-unit">平方米</span>
            </li>
          </ul>
          <!-- 网格类型为路段时显示 -->
          <div class="section-title"
               v-show="type===3">
            <span>检测信息</span>
          </div>
          <ul class="form-ul"
              v-show="type===3">
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>线宽：</p>
              <Input v-model="lineWidth"
                     placeholder="线宽"
                     maxlength="30"
                     v-floatLimit
                     class="m-r-5"
                     style="width:300px">
              </Input><span class="li-unit">米</span>
            </li>
          </ul>
          <div class="section-title">
            <span>考勤设置</span>
            <!-- <p><Button type="default"
                    @click.stop="onClickgridCheckworkList">
              <Icon custom="i-icon icon-tianjia1"
                    size="15"></Icon> 新增打卡时间
            </Button>
            </p> -->
            <p class="buttom-title"
               @click.stop="onClickgridCheckworkList">
              <Icon custom="i-icon icon-tianjia1"
                    size="16" />
              新增打卡时间
            </p>
          </div>
          <div class="time-right flex a-center">
            <span class="set-time"
                  @click.stop="onClickTime">设置冬夏时间</span>
            <RadioGroup v-model="classes"
                        @on-change="onChangeRadioGroup">
              <Radio :label="1">固定班</Radio>
              <Radio :label="2">冬夏班</Radio>
            </RadioGroup>
            <Poptip trigger="hover">
              <Icon custom="i-icon icon-tishi1"
                    size="22"
                    color="#25bb96"
                    class="cursor-pointer" />
              <div slot="content">
                <p class="li-title">固定班:不会根据季节更改上下班时间</p>
                <p class="li-title">冬夏班:会根据季节更改上下班时间</p>
                <p class="li-title">当前系统内,10月01号~05月01号为冬季,05月01号~10月01号为夏季</p>
              </div>
            </Poptip>
          </div>
          <!-- <ul class="form-ul"
              v-for="(item,index) in gridCheckworkList"
              :key="index">
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>考勤段名称：</p>
              <Input v-model="item.checkWorkName"
                     placeholder="考勤段名称"
                     maxlength="30"
                     clearable
                     style="width:200px"
                     v-intLimit></Input>
              <Button class="m-b-15"
                      type="text"
                      v-if="index != 0"
                      @click.stop="onClickDeleteClock(index)">
                <Icon custom="i-icon icon-shanchu"
                      size="15"></Icon> 删除
              </Button>

            </li>
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>打卡时间：</p>
              <TimePicker v-model="item.supervisionTime"
                          format="HH:mm"
                          type="timerange"
                          separator=" 至 "
                          :clearable="false"
                          confirm
                          style="width:300px"
                          v-intLimit></TimePicker>
            </li>

          </ul> -->
          <div class="time-cont"
               v-if="classes == 1">
            <ul class="form-ul"
                v-for="(item,index) in gridCheckworkList"
                :key="index">
              <li class="form-ul-li">
                <p class="li-title"><span class="li-must">*</span>考勤段名称：</p>
                <Input v-model="item.checkWorkName"
                       maxlength="30"
                       clearable
                       v-stringLimit></Input>
                <Button class="m-b-15"
                        type="text"
                        v-if="index != 0"
                        @click.stop="onClickDeleteClock(index)">
                  <Icon custom="i-icon icon-shanchu"
                        size="15"></Icon> 删除
                </Button>
              </li>
              <li class="form-ul-li">
                <p class="li-title"><span class="li-must">*</span>上班时间：</p>
                <TimePicker format="HH:mm"
                            placeholder=""
                            v-model="item.startTime"></TimePicker>
              </li>
              <li class="form-ul-li">
                <p class="li-title"><span class="li-must">*</span>下班时间：</p>
                <TimePicker format="HH:mm"
                            placeholder=""
                            v-model="item.endTime"></TimePicker>
              </li>
              <!-- <li v-show="index != 0"
                @click.stop="onClickDeleteClock(index)">
              <Icon custom="i-icon icon-shanchu1"
                    size="16"
                    color="#25bb96"
                    style="line-height:32px" />
              <span class="m-l-5">删除</span>
            </li> -->
            </ul>
          </div>
          <div class="time-cont season-cont"
               v-if="classes == 2">
            <ul class="form-ul"
                v-for="(item,index) in gridCheckworkList"
                :key="index">
              <li class="form-ul-li">
                <p class="li-title"><span class="li-must">*</span>考勤段名称：</p>
                <Input v-model="item.checkWorkName"
                       maxlength="30"
                       v-stringLimit></Input>
                <Button class="m-b-15"
                        type="text"
                        v-if="index != 0"
                        @click.stop="onClickDeleteClock(index)">
                  <Icon custom="i-icon icon-shanchu"
                        size="14"></Icon> 删除
                </Button>
              </li>
              <li class="form-ul-li">
                <p class="li-title"><span class="li-must">*</span>夏上班时间：</p>
                <TimePicker format="HH:mm"
                            placeholder=""
                            v-model="item.sumStartTime"></TimePicker>

                <p class="li-title"><span class="li-must">*</span>夏下班时间：</p>
                <TimePicker format="HH:mm"
                            placeholder=""
                            v-model="item.sumEndTime"></TimePicker>
              </li>
              <li class="form-ul-li">
                <p class="li-title"><span class="li-must">*</span>冬上班时间：</p>
                <TimePicker format="HH:mm"
                            placeholder=""
                            v-model="item.winStartTime"></TimePicker>

                <p class="li-title"><span class="li-must">*</span>冬下班时间：</p>
                <TimePicker format="HH:mm"
                            placeholder=""
                            v-model="item.winEndTime"></TimePicker>
              </li>
              <!-- <li v-show="index != 0"
                @click.stop="onClickDetailTime(index)">
              <Icon custom="i-icon icon-shanchu1"
                    size="16"
                    color="#25bb96"
                    style="line-height:32px" />
              <span class="m-l-5">删除</span>
            </li> -->
            </ul>
          </div>
          <div class="section-title">
            <span>打卡规则</span>
          </div>
          <ul class="form-ul">
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>允许提前打卡：</p>
              <Input v-model="advance"
                     maxlength="30"
                     clearable
                     class="m-r-5"
                     style="width:300px"></Input><span class="li-unit">分钟</span>
            </li>
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>迟到：晚打卡 </p>
              <Input v-model="late"
                     maxlength="30"
                     clearable
                     class="m-r-5"
                     style="width:300px"></Input><span class="li-unit">分钟</span>
            </li>
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>早退：早打卡 </p>
              <Input v-model="leaveEarly"
                     maxlength="30"
                     clearable
                     class="m-r-5"
                     style="width:300px"></Input><span class="li-unit">分钟</span>
            </li>
            <li class="form-ul-li">
              <p class="li-title"><span class="li-must">*</span>旷工：晚打卡 </p>
              <Input v-model="absenteeism"
                     maxlength="30"
                     clearable
                     class="m-r-5"
                     style="width:300px"></Input><span class="li-unit">分钟</span>
            </li>
            <!-- <div>当旷工值为“0”，按照上下班都为缺卡统计旷工，当旷工有数值时，按照此数值计算旷工。</div> -->
          </ul>
        </div>
        <div class="drawer-cont-right">
          <div id="enclosureModalMapDiv"
               class="mapDiv"
               ref="enclosureModalMapDiv">
          </div>
          <div class="map-top">
            <AutoComplete ref="search"
                          v-model="searchValue"
                          placeholder=""
                          size="large"
                          @on-search="onSearch"
                          @on-select="onSelect"
                          style="width:300px">
              <Option v-for="item in searchData"
                      :value="item.hotPointID"
                      :key="item.hotPointID">{{ item.name }}</Option>
            </AutoComplete>
            <!-- 区域显示 -->
            <div class="map-top-btns"
                 v-show="type===1">
              <!-- <span @click.stop="drawMarker"
                    :class="{current:drawType===0}">
                <Icon custom="i-icon icon-biaojidian"
                      size="16" />
                标记点
              </span> -->
              <!-- <span @click.stop="onClickMapBtn(1)"
                    :class="{current:drawType=='1'}">
                <Icon custom="i-icon icon-hangzhengquyu"
                      size="16" />
                行政区域
              </span> -->
              <span @click.stop="onClickResetDraw(1)"
                    :class="{current:drawType===1}">
                <Icon custom="i-icon icon-hangzhengquyu"
                      size="16" />
                圆形区域
              </span>
              <span @click.stop="onClickResetDraw(2)"
                    :class="{current:drawType===2}">
                <Icon custom="i-icon icon-duoxuan"
                      size="16" />
                矩形区域
              </span>
              <span @click.stop="onClickResetDraw(3)"
                    :class="{current:drawType===3}">
                <Icon custom="i-icon icon-duobianxing"
                      size="16" />
                多边形区域
              </span>
            </div>

            <!-- 路段显示 -->
            <div class="map-top-btns"
                 v-show="type===3">
              <span @click.stop="onClickResetLine"
                    :class="{current:drawType===4}">
                <Icon custom="i-icon icon-daolu"
                      size="16" />
                自定义路段
              </span>
              <!-- <span @click.stop="onClickMapBtn(1)"
                    :class="{current:drawType=='1'}">
                <Icon custom="i-icon icon-luduanguihua"
                      size="16" />
                路段规划
              </span> -->
              <!-- <span @click.stop="onClickMapBtn(2)"
                    :class="{current:drawType=='2'}">
                <Icon custom="i-icon icon-guiji"
                      size="16" />
                行驶轨迹规划
              </span> -->
            </div>

            <div class="map-top-btns"
                 @click.stop="onClickResetDraw(drawType)"
                 v-show="resetVisible">
              <span>
                <Icon custom="i-icon icon-saoba"
                      size="16" />
                重绘
              </span>
            </div>

          </div>
          <div class="map-bottom">
            <i-switch />已绘网格显示
          </div>
        </div>
      </div>
      <div class="drawer-footer">
        <Button @click.stop="onClickCancel">取消</Button>
        <Button type="primary"
                :loading="loading"
                @click.stop="onClickConfirm">确定</Button>
      </div>

    </div>
    <!-- 设置冬夏时间 -->
    <!-- <WorkTimeModal v-model="workTimeVisible"
                   :dataId.sync="workTimeId"></WorkTimeModal> -->
  </Drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '@/utils/tMap'
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { timeDifference } from '@/utils/dateRangUtil'
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '网格'
    },
    width: Number,
    dataId: String,
    sourceType: String,
    itemIds: String
  },
  data () {
    return {
      loading: false,
      height: 0,
      name: '', // 网格名称
      depart: '', // 所属机构
      departId: '', // 所属机构id
      classes: 1, // 班组类型
      type: 1, // 网格类型
      gridCheckworkList: [
        {
          checkWorkName: '',
          startTime: '',
          endTime: '',
          sumStartTime: '',
          sumEndTime: '',
          winStartTime: '',
          winEndTime: '',
        }
      ],
      typeArr: [
        {
          id: 1,
          name: '区域'
        },
        {
          id: 2,
          name: '线路'
        },
        {
          id: 3,
          name: '路段'
        }
      ],
      sideWidth: '', // 描边宽度
      sideWidthArr: [
        {
          id: 2,
          name: 2
        },
        {
          id: 3,
          name: 3
        },
        {
          id: 4,
          name: 4
        },
        {
          id: 5,
          name: 5
        },
        {
          id: 6,
          name: 6
        },
        {
          id: 7,
          name: 7
        },
        {
          id: 8,
          name: 8
        },
        {
          id: 9,
          name: 9
        },
        {
          id: 10,
          name: 10
        },
        {
          id: 11,
          name: 11
        },
        {
          id: 12,
          name: 12
        },
        {
          id: 13,
          name: 13
        },
        {
          id: 14,
          name: 14
        },
        {
          id: 15,
          name: 15
        },
        {
          id: 16,
          name: 16
        },
        {
          id: 17,
          name: 17
        },
        {
          id: 18,
          name: 18
        },
        {
          id: 19,
          name: 19
        },
        {
          id: 20,
          name: 20
        },
      ],
      section: '', // 路段
      sectionId: '', // 路段id
      sectionArr: [],
      area: '', // 面积
      lineWidth: '20', // 线宽
      guideboard: 1, // 设置为路牌
      mileage: '', // 里程

      // gridCheckworkList: [
      //   {
      //     checkWorkName: '',
      //     supervisionTime: '',
      //   },
      // ],

      // 监管规则 start
      supervisionTime: '',
      advance: '',
      late: '',
      leaveEarly: '',
      absenteeism: '',
      // 监管规则 end

      // 地图相关 start
      map: null,  // 天地图实例
      zoom: 14, // 初始地图缩放等级
      searchValue: '',
      searchData: [],
      config: {
        color: "#0079fe",
        weight: 3,
        opacity: 0.8,
        fillColor: "#0079fe",
        fillOpacity: 0.3
      },
      drawType: 1, // 地图按钮
      drawTool: null,
      overlay: null,
      resetVisible: false,
      // 地图相关 end
      // 设置冬夏时间
      workTimeVisible: false,
      workTimeId: null
    };
  },
  watch: {
    visible (newVal) {
      newVal && this.init()
    },
    dataId (newValue) {
      newValue && this.getDetail()
    },
  },
  computed: {
    ...mapGetters({
      'mapCenterLnglat': 'getMapCenterLnglat',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
  },
  mounted () {
    this.height = window.innerHeight - 160
    this.initData()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
    ]),
    init () {
      if (!this.map) this.createMap()
      this.updateDepartmentTreeList()
    },
    initData () {
      this.$http.getGridTreeList({ type: 3 }).then(res => {
        if (res.code === 200) {
          this.sectionArr = res.result
        }
      })
    },
    getDetail () {
      this.$store.dispatch('getGridDetail', { gridId: this.dataId }).then(res => {
        let points
        let viewport
        console.log(res);
        for (const i in res) {
          this[i] = res[i]
        }
        this.classes = res.gridCheckworkVoList[0].reqType == 0 ? 1 : 2
        this.gridCheckworkList = []
        this.gridCheckworkList = res.gridCheckworkVoList

        switch (res.drawType) {
          case 3:
            // 圆形
            this.drawType = 1
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            this.drawTool = new T.CircleTool(this.map, this.config);
            points = new T.LngLat(res.lng, res.lat)
            this.overlay = new T.Circle(points, res.radius, this.config)
            this.overlay.enableEdit()
            this.$nextTick(() => {
              // 监听可拖动点的dom 鼠标按下事件
              let prevBtn = document.querySelector('.mapDiv .tdt-edit-resize')
              prevBtn.addEventListener("mouseup", this.onCircleResize);
              prevBtn.addEventListener("click", this.onCircleResize);
            })
            this.map.addOverLay(this.overlay)
            let bounds = this.overlay.getBounds()
            viewport = this.map.getViewport([bounds.Lq, bounds.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            // 计算面积
            let radius = this.overlay.getRadius()
            this.area = parseInt(Math.PI * radius * radius)
            break;
          case 4:
            // 矩形
            this.drawType = 2
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            this.drawTool = new T.RectangleTool(this.map, this.config);
            points = JSON.parse(res.points)
            this.overlay = new T.Rectangle([points.Lq, points.kq], this.config)
            this.overlay.enableEdit()
            this.$nextTick(() => {
              // 监听可拖动点的dom 鼠标按下事件
              let prevBtn = document.getElementsByClassName("tdt-edit-resize")
              prevBtn.forEach(item => {
                item.addEventListener("mouseup", this.onRectangleResize);
                item.addEventListener("click", this.onRectangleResize);
              })
            })
            this.map.addOverLay(this.overlay)
            viewport = this.map.getViewport([points.Lq, points.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            // 计算面积
            let point1 = this.overlay.getBounds().Lq
            let point2 = this.overlay.getBounds().kq
            let point3 = new T.LngLat(point1.lng, point2.lat)
            let width = this.map.getDistance(point1, point3)
            let height = this.map.getDistance(point2, point3)
            this.area = parseInt(width * height)
            break;
          case 5:
            // 多边形
            this.drawType = 3
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            this.drawTool = new T.PolygonTool(this.map, this.config);
            points = JSON.parse(res.points)
            this.overlay = new T.Polygon(points, this.config)
            this.overlay.enableEdit()
            this.$nextTick(() => {
              // 监听可拖动点的dom 鼠标按下事件
              let prevBtn = document.getElementsByClassName("tdt-marker-draggable")
              prevBtn.forEach(item => {
                item.addEventListener("mouseup", this.onPolygonResize);
                item.addEventListener("click", this.onPolygonResize);
              })
            })
            this.map.addOverLay(this.overlay)
            let lnglatList = [];
            points.forEach(pList => {
              // pList.forEach(p => {
              let lnglat = new T.LngLat(pList.lng, pList.lat)
              lnglatList.push(lnglat)
              // })
            })
            viewport = this.map.getViewport(lnglatList)
            this.map.panTo(viewport.center, viewport.zoom)
            // 计算面积
            let tool = new T.PolygonTool(this.map, this.config)
            this.area = parseInt(tool.getArea(lnglatList))
            break;
          case 6:
            // 线
            this.drawType = 4
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            // 计算公里数
            if (this.type == 3) {
              // 折线控件
              this.drawTool = new T.PolylineTool(this.map, this.config)
              // 单路段 - 画网格
              points = JSON.parse(res.points)
              // 折线覆盖物
              this.overlay = new T.Polyline(points, this.config)
              this.overlay.enableEdit()
              // 监听可拖动点的dom 鼠标按下事件
              this.$nextTick(() => {
                let prevBtn = document.getElementsByClassName("tdt-marker-draggable")
                prevBtn.forEach(item => {
                  item.addEventListener("mouseup", this.onLinePointsResize);
                  item.addEventListener("click", this.onLinePointsResize);
                })
              })
              this.map.addOverLay(this.overlay)
              viewport = this.map.getViewport(points)
              this.map.panTo(viewport.center, viewport.zoom)
              // 单路段 - 计算面积和距离
              let lnglatList = [];
              points.forEach(p => {
                let lnglat = new T.LngLat(p.lng, p.lat)
                lnglatList.push(lnglat)
              })
              this.mileage = parseInt(this.drawTool.getDistance(lnglatList))
              this.area = parseInt(this.mileage * this.lineWidth)
            }
            break;
          default:
            this.drawType = 4
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            // 多路段组成的路线 - 画网格
            let sectionIdList = res.sectionId.split(",")
            let selectSectionList = this.getSelectSectionList(sectionIdList, this.sectionArr)
            let totalKm = 0
            let totalArea = 0
            let totalPoints = []
            selectSectionList.forEach(section => {
              if (section.km) {
                totalKm += section.km;
              }
              if (section.area) {
                totalArea += section.area;
              }
              let points = JSON.parse(section.lnglat)
              this.overlay = new T.Polyline(points, this.config)
              this.map.addOverLay(this.overlay)
              points.forEach(p => {
                totalPoints.push(p)
              })
            })
            this.mileage = totalKm
            this.area = totalArea
            let viewport = this.map.getViewport(totalPoints)
            this.map.panTo(viewport.center, viewport.zoom)
            // 多路段组成的路线 - 计算面积和距离
            break;
        }
        // this.supervisionTime = [res.startTime, res.endTime]
        // this.gridCheckworkList = res.gridCheckworkList.map(item => {
        //   return {
        //     checkWorkName: item.checkWorkName,
        //     supervisionTime: [item.startTime, item.endTime],
        //   }
        // })
      })
    },
    // 递归
    getSelectSectionList (sectionIdList, sectionArr) {
      let result = []
      sectionArr.forEach(item => {
        if (sectionIdList.includes(item.id)) {
          let resultItem = { km: item.km, area: item.area, lnglat: item.lnglat }
          result.push(resultItem)
        }
        if (item.children && item.children.length > 0) {
          this.getSelectSectionList(sectionIdList, item.children).forEach(i => {
            result.push(i)
          })
        }
      })
      return result;
    },
    // 固定班与冬夏班单选切换
    onChangeRadioGroup (value) {
      console.log(value);
      this.gridCheckworkList = [{
        checkWorkName: '',
        startTime: '',
        endTime: '',
        sumStartTime: '',
        sumEndTime: '',
        winStartTime: '',
        winEndTime: '',
      }]
    },
    // 选择所属机构
    onChangeDepartment (section) {
      this.departId = section.id
      this.depart = section.name
      this.$refs.deptTreeRef.resetTreeData()
    },
    // 修改网格类型
    onChangeType (value) {
      switch (value) {
        case 1:
          this.drawType = 1
          break;
        case 3:
          this.drawType = 4
          break;
        default:
          this.drawType = 0
          break;
      }
      this.onClickResetDraw(this.drawType)
    },
    // 选择路段
    onChangeSection (section) {
      // 清除历史地图覆盖物
      this.map.clearOverLays()
      // 新增当前选中的路段覆盖物
      let idArr = []
      let valueArr = []
      let tool = new T.PolylineTool(this.map, this.config)
      let area = 0
      let mileage = 0
      let totalPoints = []
      section.forEach(item => {
        if (item.type !== 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
          // 在地图上画线
          if (item.lnglat) {
            let points = JSON.parse(item.lnglat)
            this.map.addOverLay(new T.Polyline(points, this.config))
            points.forEach(p => {
              totalPoints.push(p)
            })
          }
          if (item.area) {
            area += item.area
          }
          if (item.km) {
            mileage += item.km
          }
        }
      })
      let viewport = this.map.getViewport(totalPoints)
      this.map.panTo(viewport.center, viewport.zoom)
      this.sectionId = idArr.join()
      this.section = valueArr.join()
      this.mileage = parseInt(mileage)
      this.area = parseInt(area)
    },
    // 生成地图
    createMap () {
      tMap.init().then((T) => {
        this.map = new T.Map('enclosureModalMapDiv')
        this.setMapCenterOrZoom()
        !this.dataId && this.drawCircle()
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败,请刷新浏览器')
      })
    },
    // 设置地图中心点与缩放等级
    setMapCenterOrZoom () {
      let lnglat
      if (this.mapCenterLnglat) {
        let mapCenterLnglat = this.mapCenterLnglat.split(',')
        lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
      } else {
        lnglat = new T.LngLat(119.130974, 36.706688)
      }
      this.map.centerAndZoom(lnglat, this.zoom)
    },
    // 搜索地址
    onSearch (val) {
      this.searchValue = val
      let config = {
        pageCapacity: 10,
        onSearchComplete: this.onSearchAddress
      }
      let LocalSearch = new T.LocalSearch(this.map, config)
      LocalSearch.search(this.searchValue, 1)
    },
    onSearchAddress (e) {
      this.searchData = e.getPois()
    },
    onSelect (val) {
      let obj = this.searchData.find(item => item.hotPointID === val)
      this.$nextTick(() => {
        this.searchValue = obj.name
      })
      let arr = obj.lonlat.split(' ')
      this.map.panTo(new T.LngLat(arr[0], arr[1]), this.zoom)
      let zoom = 16
      //创建图片对象
      let icon = new T.Icon({
        iconUrl: require('../../../../../assets/main/guidepost.png'),
        iconSize: new T.Point(24, 32),
        iconAnchor: new T.Point(10, 30)
      })
      let lnglat = new T.LngLat(arr[0], arr[1])
      let marker = new T.Marker(lnglat, { icon: icon })
      this.map.addOverLay(marker)
      this.map.panTo(new T.LngLat(arr[0], arr[1]), zoom)
    },
    // // 标记点
    // drawMarker () {
    //   this.drawType = 0
    //   this.resetVisible = false
    //   this.drawTool && this.drawTool.close()
    //   this.drawTool = new T.MarkTool(this.map, { follow: true });
    //   this.map.clearOverLays();
    //   this.drawTool.open();
    //   //绑定mouseup事件 在用户每完成一次标注时触发事件。
    //   this.drawTool.addEventListener("mouseup", this.getMarkerPoints);
    // },
    // // 获取标记点坐标
    // getMarkerPoints (e) {
    //   this.resetVisible = true
    //   var points = e.currentLnglat;
    //   //标注点的坐标
    //   console.log(points);
    // },
    // 圆形区域
    drawCircle () {
      this.drawTool = new T.CircleTool(this.map, this.config);
      this.drawTool.open();
      //绑定drawend事件 用户完成绘制圆时触发
      this.drawTool.addEventListener("drawend", this.getCirclePoints);
    },
    // 获取圆形区域坐标
    getCirclePoints (e) {
      this.resetVisible = true
      this.overlay = e.currentCircle
      this.overlay.enableEdit()
      //中心点坐标
      let radius = e.currentRadius
      this.area = parseInt(Math.PI * radius * radius)
      // this.points = e.currentCenter
      // 监听可拖动点的dom 鼠标按下事件
      let prevBtn = document.querySelector('.mapDiv .tdt-edit-resize')
      prevBtn.addEventListener("mouseup", this.onCircleResize);
      prevBtn.addEventListener("click", this.onCircleResize);
    },
    // 计算圆形  修改后面积
    onCircleResize () {
      let radius = this.overlay.getRadius()
      this.area = parseInt(Math.PI * radius * radius)
    },
    // 矩形区域
    drawRectangle () {
      this.drawTool = new T.RectangleTool(this.map, this.config);
      this.drawTool.open();
      //绑定draw事件 用户每次完成拉框操作时触发事件。
      this.drawTool.addEventListener("draw", this.getRectanglePoints);
    },
    // 获取矩形区域坐标
    getRectanglePoints (e) {
      this.resetVisible = true
      this.overlay = e.currentRectangle
      this.overlay.enableEdit()
      // 计算矩形面积
      let point1 = this.overlay.getBounds().Lq
      let point2 = this.overlay.getBounds().kq
      let point3 = new T.LngLat(point1.lng, point2.lat)
      let width = this.map.getDistance(point1, point3)
      let height = this.map.getDistance(point2, point3)
      this.area = parseInt(width * height)
      // this.points = e.currentBounds
      // 监听可拖动点的dom 鼠标按下事件
      let prevBtn = document.getElementsByClassName("tdt-edit-resize")
      prevBtn.forEach(item => {
        item.addEventListener("mouseup", this.onRectangleResize);
        item.addEventListener("click", this.onRectangleResize);
      })
    },
    // 新增打卡时间
    onClickgridCheckworkList () {
      if (this.gridCheckworkList.length == 4) {
        this.$Message.info({
          content: '最多设置4个考勤时间段'
        })
        return
      }
      this.gridCheckworkList.push({
        checkWorkName: '',
        supervisionTime: '',

      })
    },
    // // 添加时间段
    // onClickgridCheckworkList () {
    //   this.gridCheckworkList.push({})
    // },
    // 删除打卡时间
    onClickDeleteClock (index) {
      this.gridCheckworkList.splice(index, 1)
    },
    getEepetitionArr () {

    },
    // 计算矩形 修改后面积
    onRectangleResize () {
      // this.overlay = this.drawTool.getRectangles()[0]
      let point1 = this.overlay.getBounds().Lq
      let point2 = this.overlay.getBounds().kq
      let point3 = new T.LngLat(point1.lng, point2.lat)
      let width = this.map.getDistance(point1, point3)
      let height = this.map.getDistance(point2, point3)
      this.area = parseInt(width * height)
    },
    // 多边形区域
    drawPolygon () {
      this.drawTool = new T.PolygonTool(this.map, this.config);
      this.drawTool.open();
      //绑定draw事件 用户双击完成一次折线绘制时触发事件。
      this.drawTool.addEventListener("draw", this.getPolygonPoints);
    },
    // 获取多边形区域坐标
    getPolygonPoints (e) {
      this.resetVisible = true
      this.overlay = e.currentPolygon
      this.overlay.enableEdit()
      this.area = parseInt(e.currentArea)
      //用户当前绘制的多边形的点坐标数组
      // this.points = e.currentLnglats
      // 监听可拖动点的dom 鼠标按下事件
      let prevBtn = document.getElementsByClassName("tdt-marker-draggable")
      prevBtn.forEach(item => {
        item.addEventListener("mouseup", this.onPolygonResize);
        item.addEventListener("click", this.onPolygonResize);
      })
    },
    // 计算多边形 修改后面积
    onPolygonResize () {
      let lnglatList = [];
      this.overlay.getLngLats().forEach(pList => {
        pList.forEach(p => {
          let lnglat = new T.LngLat(p.lng, p.lat)
          lnglatList.push(lnglat)
        })
      })
      this.area = parseInt(this.drawTool.getArea(lnglatList))
    },
    // 线
    drawLine () {
      this.drawTool = new T.PolylineTool(this.map, this.config);
      this.drawTool.open();
      //绑定draw事件 用户每次完成拉框操作时触发事件。
      this.drawTool.addEventListener("draw", this.getLinePoints);
    },
    // 获取线坐标
    getLinePoints (e) {
      this.resetVisible = true
      let currentPolyline = e.currentPolyline
      if (this.overlay) {
        let newLnglat = currentPolyline.getLngLats()
        let oldLngLat = this.overlay.getLngLats()
        let lnglat = oldLngLat.concat(newLnglat)
        this.overlay = new T.Polyline(lnglat, this.config)
        this.map.clearOverLays()
        this.map.addOverLay(this.overlay)
      } else {
        this.overlay = currentPolyline
      }
      this.overlay.enableEdit()
      this.mileage = parseInt(this.drawTool.getDistance(this.overlay.getLngLats()))
      this.area = this.mileage * this.lineWidth
      //用户当前绘制的折线的点坐标数组
      // console.log("用户当前绘制的折线的地理长度:" + e.currentDistance);
      // this.points = e.currentLnglats
      // 监听可拖动点的dom 鼠标按下事件
      let prevBtn = document.getElementsByClassName("tdt-marker-draggable")
      prevBtn.forEach(item => {
        item.addEventListener("mouseup", this.onLinePointsResize);
        item.addEventListener("click", this.onLinePointsResize);
      })
    },
    // 计算折线 修改后面积
    onLinePointsResize () {
      this.mileage = parseInt(this.drawTool.getDistance(this.overlay.getLngLats()))
      this.area = this.mileage * this.lineWidth
    },
    // 点击重绘
    onClickResetDraw (type) {
      this.drawType = type
      // this.points = null
      this.overlay = null
      // this.radius = ''
      this.resetVisible = false
      this.drawTool && this.drawTool.close()
      this.map.clearOverLays()
      this.mileage = ''
      this.area = ''
      switch (this.type) {
        case 1:
          switch (this.drawType) {
            case 1:
              this.drawCircle()
              break;
            case 2:
              this.drawRectangle()
              break;
            case 3:
              this.drawPolygon()
              break;
            default:
              break;
          }
          break;
        case 3:
          this.drawLine()
          break;
        default:
          break;
      }
    },
    // 点击自定义路段
    onClickResetLine () {
      this.drawLine()
    },
    // 设置冬夏时间
    onClickTime () {
      this.workTimeVisible = true
      this.workTimeId = this.ruleType
    },
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入网格名称');
      if (!this.depart) return this.$Message.info('请选择所属机构');
      if (!this.type) return this.$Message.info('请选择网格类型');
      if (this.type === 1) {
        if (!this.overlay) return this.$Message.info('请绘制区域');
      } else if (this.type === 2) {
        if (!this.section) return this.$Message.info('请选择路段');
      } else if (this.type === 3) {
        if (!this.overlay) return this.$Message.info('请绘制路段');
        if (!this.lineWidth) return this.$Message.info('请输入线宽');
      }
      if (this.advance == '') return this.$Message.info('请输入允许提前打卡时间');
      if (this.late == '') return this.$Message.info('请输入迟到时间');
      if (this.leaveEarly == '') return this.$Message.info('请输入早退时间');
      if (this.absenteeism == '') return this.$Message.info('请输入旷工时间');
      if (this.absenteeism != 0) {
        if (this.absenteeism <= this.late) {
          this.$Message.info('旷工时间需大于迟到时间');
          return
        }
      }
      if (this.advance == 0 && this.late == 0) {
        this.$Message.info('提前打卡与迟到不能同时为0');
        return
      }
      let timeDiffArr = []
      // 固定班
      if (this.classes == 1) {
        for (const item of this.gridCheckworkList) {
          if (!item.checkWorkName) return this.$Message.info('请输入考勤段名称')
          if (!item.startTime) return this.$Message.info('请输入上班时间')
          if (!item.endTime) return this.$Message.info('请输入下班时间')
        }
        timeDiffArr = this.gridCheckworkList.map(item => {
          return timeDifference(item.startTime, item.endTime)
        })
      }
      // 冬夏班
      if (this.classes == 2) {
        for (const item of this.gridCheckworkList) {
          if (!item.checkWorkName) return this.$Message.info('请输入考勤段名称')
          if (!item.sumStartTime) return this.$Message.info('请输入夏季上班时间')
          if (!item.sumEndTime) return this.$Message.info('请输入夏季下班时间')
          if (!item.winStartTime) return this.$Message.info('请输入冬季下班时间')
          if (!item.winEndTime) return this.$Message.info('请输入冬季下班时间')
        }
        this.gridCheckworkList.forEach(item => {
          timeDiffArr.push(timeDifference(item.sumStartTime, item.sumEndTime))
          timeDiffArr.push(timeDifference(item.winStartTime, item.winEndTime))
        })
      }
      let minTimeDiff = Math.min(...timeDiffArr)
      if (this.absenteeism >= minTimeDiff) return this.$Message.info('旷工时间过大，需小于最短考勤时间段');
      this.loading = true
      let params = {
        name: this.name,
        departId: this.departId,
        depart: this.depart,
        type: this.type,
        area: this.area,

        lineWidth: this.lineWidth,
        mileage: this.mileage,
        // 监管
        // itemId: this.itemIds,
        leaveEarly: this.leaveEarly,
        advance: this.advance,
        late: this.late,
        absenteeism: this.absenteeism,
        // gridCheckworkList: this.gridCheckworkList,
        // startTime: this.supervisionTime[0],
        // endTime: this.supervisionTime[1],
        gridType: 1,
      }
      params.gridCheckworkList = this.gridCheckworkList.map(item => {
        if (this.classes == 1) {
          item.reqType = 0
        } else {
          item.reqType = 1
        }
        return item
      })
      // if (this.classes == 1) {
      //   params.reqType = 0

      // } else {
      //   params.reqType = 1
      // }
      // params.gridCheckworkList = this.gridCheckworkList.map(item => {
      //   return {
      //     checkWorkName: item.checkWorkName,
      //     // startTime: item.supervisionTime[0],
      //     // endTime: item.supervisionTime[1],
      //   }
      // })
      switch (this.type) {
        case 1:
          switch (this.drawType) {
            case 1:
              params.drawType = 3
              params.lng = this.overlay.getCenter().lng
              params.lat = this.overlay.getCenter().lat
              params.radius = this.overlay.getRadius()
              break;
            case 2:
              params.drawType = 4
              let data = this.overlay.getBounds()
              params.rectangularEntity = {
                lq: data.Lq,
                kq: data.kq
              }
              break;
            case 3:
              params.drawType = 5
              params.pointsNew = this.overlay.getLngLats()
              params.pointsNew = params.pointsNew[0]
              break;
            default:
              break;
          }
          break;
        case 2:
          params.sectionId = this.sectionId
          params.section = this.section
          break;
        case 3:
          params.drawType = 6
          params.pointsNew = this.overlay.getLngLats()
          params.guideboard = this.guideboard
          break;
        default:
          break;
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditGrid', params).then(res => {
        this.loading = false
        if (res.result.gridId) {
          this.$Message.info(res.message)
          this.$emit('onClickConfirm', res.result)
          this.$emit('onChange', false)
          this.initData()
        } else {
          this.$Message.info('网格名称重复')
        }
      }).catch(err => {
        this.loading = false
        this.$Message.info(err.message)
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('update:itemIds', '')
        this.$emit('onChange', false)
      }
    },
    resetData () {
      this.name = ''
      this.departId = ''
      this.depart = ''
      this.type = 1
      this.classes = 1
      this.sideWidth = ''
      this.sectionId = ''
      this.section = ''
      this.area = ''
      this.gridCheckworkList = [
        {
          checkWorkName: '',
          startTime: '',
          endTime: '',
          sumStartTime: '',
          sumEndTime: '',
          winStartTime: '',
          winEndTime: '',
        }
      ]
      this.lineWidth = '20'
      this.guideboard = 1
      this.mileage = ''
      this.onClickResetDraw(1)
      this.searchValue = ''
      this.searchData = []
      setTimeout(() => {
        this.setMapCenterOrZoom()
      }, 100);
      // 监管时间
      this.supervisionTime = ''
      this.advance = ''
      this.late = ''
      this.leaveEarly = ''
      this.absenteeism = ''
      // this.gridCheckworkList = [{
      //   checkWorkName: '',
      //   supervisionTime: '',
      // }]
    }
  },
}
</script>

<style lang='scss' scoped>
::v-deep {
  .ivu-drawer-wrap {
    z-index: 2000;
  }
  .ivu-drawer {
    top: 60px;
    bottom: 0;
    .ivu-drawer-body {
      padding: 0;
    }
  }
  .modal-cont .form-ul .form-ul-li {
    .ivu-checkbox-default {
      line-height: 1;
    }
    .li-title {
      min-width: 110px;
    }
    .li-unit {
      min-width: 45px;
    }
  }
}
.modal-cont {
  max-height: none;
}
.drawer {
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  .drawer-cont {
    flex: 1;
    display: flex;
    .drawer-cont-left {
      width: 480px;
      // height: 100%;
      padding: 0 10px;
      // overflow: auto;
      .li-color {
        display: flex;
        align-items: center;
        li {
          width: 22px;
          height: 22px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            width: 15px;
            height: 15px;
            cursor: pointer;
            display: flex;
          }
        }
        li:nth-child(1) span {
          border: 1px solid #1f86ea;
          background: rgba(31, 134, 234, 0.2);
        }
        li:nth-child(2) span {
          border: 1px solid #129b3a;
          background: rgba(18, 155, 58, 0.2);
        }
        li:nth-child(3) span {
          border: 1px solid rgb(244, 147, 58);
          background: rgba(244, 147, 58, 0.2);
        }
        li:nth-child(4) span {
          border: 1px solid rgb(71, 85, 238);
          background: rgba(71, 85, 238, 0.2);
        }
        li:nth-child(5) span {
          border: 1px solid rgb(244, 57, 57);
          background: rgba(244, 57, 57, 0.2);
        }
        .current {
          background: #bbdaf9;
        }
      }
    }
    .drawer-cont-right {
      flex: 1;
      position: relative;
      .mapDiv {
        width: 100%;
        height: 100%;
      }
      .map-top {
        display: flex;
        z-index: 500;
        position: absolute;
        top: 20px;
        left: 20px;
        .map-top-btns {
          margin-left: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          color: #000;
          border-radius: 4px;
          background: #ffffff;
          box-shadow: 0 2px 1px 0 rgba(31, 132, 235, 0.2);
          span {
            margin-right: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            i {
              margin-right: 4px;
            }
          }
          span:last-child {
            margin-right: 0;
          }
          .current {
            color: #0079fe;
          }
        }
      }
      .map-bottom {
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        color: #000;
        background: #ffffff;
        box-shadow: 0 2px 1px 0 rgba(31, 132, 235, 0.2);
        z-index: 500;
        position: absolute;
        bottom: 20px;
        left: 20px;
        span {
          margin-right: 4px;
        }
      }
    }
  }
  .drawer-footer {
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    border-top: 1px solid #e1e3e5;
    text-align: right;
    padding: 0 20px;
    button {
      margin-left: 6px;
    }
  }
}
.map-grid {
  ::v-deep {
    .ivu-drawer {
      top: 0;
    }
  }
  .drawer {
    height: calc(100%);
  }
}
.time-right {
  margin-left: 12px;
  margin-bottom: 10px;
  color: #25bb96;
  .set-time {
    margin-right: 5px;
    cursor: pointer;
  }
}
.buttom-title {
  width: 100px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border: 1px solid #25bb96;
  color: #25bb96;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}
</style>