<template>
  <Modal :value="visible"
         :title="title"
         width="740"
         footer-hide
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="equipment">
      <div class="equipment-filtrate">
        <Select v-model="bigType"
                placeholder="大类"
                clearable
                @on-change="onChangeBigType"
                class="m-r-5"
                style="width:170px">
          <Option v-for="item in bigTypeArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="smallType"
                placeholder="小类"
                clearable
                @on-change="onChangeSmallType"
                class="m-r-5"
                style="width:170px">
          <Option v-for="item in smallTypeArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="status"
                placeholder="是否绑定"
                @on-change="onChangeStatus"
                class="m-r-5"
                style="width:170px">
          <Option v-for="item in statusArr"
                  :value="item.id"
                  :key="item.id">{{ item.name }}</Option>
        </Select>
        <MyTreeSelect :value="name"
                      placeholder="对象名称"
                      clearable
                      :data="nameArr"
                      search
                      checked
                      @onCheckChange="onChangeName"
                      class="m-r-10"
                      style="width:170px">
        </MyTreeSelect>
      </div>
      <Table ref="table"
             :columns="columns"
             :data="data"
             height="320"
             border
             stripe
             :loading="tableLoading"
             @on-selection-change="onChangeSelect">
      </Table>
      <Page class="m-t-10 m-l-10"
            :total="totalPage"
            :current="pageNum"
            :page-size="pageSize"
            show-total
            show-sizer
            show-elevator
            @on-change="onPageChange"
            @on-page-size-change="onPageSizeChange" />
      <Button class="m-t-10 m-l-10"
              :disabled="selectArr.length===0"
              :loading="loading"
              @click.stop="onClickBind">{{this.status===1?'绑定':'解绑'}}</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MyTreeSelect from '@/components/common/MyTreeSelect'
export default {
  name: 'equipmentBindingModal',
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '绑定对象'
    },
    item: null
  },
  data () {
    return {
      bigType: '',
      smallType: '',
      smallTypeArr: [],
      status: '',
      statusArr: [
        {
          id: 1,
          name: '未绑定'
        },
        {
          id: 2,
          name: '已绑定'
        },
      ],
      nameId: '',
      name: '',
      nameArr: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
      columns: [
        {
          type: 'selection',
          width: 45,
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'faclname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施大类',
          key: 'facltypename',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施小类',
          key: 'facltypeitemname',
          width: 90,
          tooltip: true,
          align: 'center'
        },
        {
          title: '绑定状态',
          key: 'bindstatus',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text = row.bindstatus === 1 ? '已绑定' : '未绑定'
            return h('span', text);
          }
        },
        {
          title: '所属机构',
          key: 'facldeptname',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      loading: false
    }
  },
  watch: {
    // visible (newVal) {
    //   newVal && this.init()
    // },
    // bigType (newVal) {
    //   this.onChangeBigType(newVal)
    // },
    item: {
      handler (newVal) {
        if (newVal) {
          if (newVal.bindObj) {
            this.status = 2
          } else {
            this.status = 1
          }
          this.onChangeBigType()
        }
      },
      deep: true
    },
    name (newVal) {
      if (!newVal) {
        this.onClickSearch()
      }
    }
  },
  computed: {
    ...mapGetters({
      'bigTypeArr': 'getFacilityBigTypeAllList',
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateFacilityBigTypeAllList',
    ]),
    init () {
      this.updateFacilityBigTypeAllList()
      // this.onChangeBigType()
    },
    // 修改大类
    onChangeBigType (value) {
      if (value) {
        this.$http.getFacilitySmallTypeAllList({ facilitietypeid: value }).then(res => {
          if (res.code === 200) {
            this.smallTypeArr = res.result
          }
        })
        this.$http.getBindObjectTreeList({ treetype: value }).then(res => {
          if (res.code === 200) {
            this.nameArr = res.result
          }
        })
      } else {
        this.smallTypeArr = []
        this.$http.getBindObjectTreeList({ treetype: 'facl' }).then(res => {
          if (res.code === 200) {
            this.nameArr = res.result
          }
        })
      }
      this.smallType = ''
      this.onClickSearch()
    },
    onChangeSmallType () {
      this.onClickSearch()
    },
    onChangeStatus () {
      this.onClickSearch()
    },
    onChangeName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type !== 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.nameId = idArr.join()
      this.name = valueArr.join()
      this.onClickSearch()
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.selectArr = []
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.selectArr = []
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.selectArr = []
      this.getList()
    },
    getList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        facltypeid: this.bigType,
        facltypeitemid: this.smallType,
        faclname: this.name,
        type: ''
      }
      if (this.status === 1) {
        params.excludeIds = this.item.bindObj || ''
      } else {
        params.ids = this.item.bindObj || ''
        if (!params.ids) return this.data = []
      }
      this.tableLoading = true
      this.$http.getFacilityList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.data = res.result.factypelist
          this.totalPage = res.result.total
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击绑定
    onClickBind () {
      let params = {
        id: this.item.gridId
      }
      let oldArr = this.item.bindObj ? this.item.bindObj.split(',').filter(item => item) : []
      if (this.status === 1) {
        // 绑定
        let newArr = oldArr.concat(this.selectArr)
        params.bindObj = newArr.join()
        this.loading = true
        this.$store.dispatch('createOrEditGrid', params).then(res => {
          this.loading = false
          this.item.bindObj = params.bindObj
          this.$emit('onClickConfirm')
        }).catch(err => {
          this.loading = false
        })
      } else {
        // 解绑
        let newArr = oldArr.filter(item => !this.selectArr.some(items => item === items))
        params.bindObj = newArr.join()
        this.$Modal.confirm({
          title: '解绑',
          content: '确认解绑?',
          onOk: () => {
            this.loading = true
            this.$store.dispatch('createOrEditGrid', params).then(res => {
              this.loading = false
              this.item.bindObj = params.bindObj
              this.$emit('onClickConfirm')
            }).catch(err => {
              this.loading = false
            })
          }
        })
      }
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:item', null)
      }
    },
    resetData () {
      this.selectArr = []
      this.bigType = ''
      this.smallType = ''
      this.nameId = ''
      this.name = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.equipment {
  .equipment-cont {
    background: #f2f2f2;
    padding: 10px;
    margin: 10px 0;
    overflow-y: scroll;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #353639;
    }
    .binding {
      padding: 4px !important;
      background-color: #fff;
      border: 1px solid #dddddd;
      margin: 11px 5px 11px 0;
      height: 22px;
      .binding-icon {
        cursor: pointer;
      }
    }
  }
  .equipment-filtrate {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
