<template>
  <Modal :value="visible"
         :title="title"
         footer-hide
         width="800"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div>
      <Table ref="table"
             :columns="columns"
             :data="data"
             height="400"
             border
             stripe
             :loading="tableLoading">
        <template slot-scope="{ row }"
                  slot="operation">
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickEditRoadAttribute(row)">修改</Button>
          <Button type="text"
                  class="tableOperaBtn"
                  @click.stop="onClickStatusRoadAttribute(row)">{{row.isEnable==1?'禁用':'恢复'}}</Button>
        </template>
      </Table>
      <div class="modal-footer m-t-10">
        <Button type="text"
                class="tableOperaBtn"
                @click.stop="gridRoadAttributeVisible=true">
          <Icon custom="i-icon icon-tianjiabiankuang"
                size="16"></Icon>
          添加
        </Button>
      </div>
    </div>

    <!-- 新增 / 修改 -->
    <GridRoadAttributeModal v-model="gridRoadAttributeVisible"
                            :dataId.sync="gridRoadAttributeId"
                            @onClickConfirm="onClickConfirm"></GridRoadAttributeModal>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GridRoadAttributeModal from '@/components/product/admin/modal/work/GridRoadAttributeModal'
export default {
  components: {
    GridRoadAttributeModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '道路属性设置'
    }
  },
  data () {
    return {
      columns: [
        {
          title: '道路属性',
          key: 'name',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '启用状态',
          key: 'isEnable',
          width: 90,
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.isEnable == 1) {
              return h('span', {
                style: {
                  color: '#25bb96',
                }
              }, '已启用')
            }
            return h('span', {
              style: {
                color: '#ff0000',
              }
            }, '已禁用')
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 120,
          align: 'center'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableLoading: false,
      // 新增 / 修改
      gridRoadAttributeVisible: false,
      gridRoadAttributeId: ''
    }
  },
  computed: {
    ...mapGetters({
      'data': 'getRoadAttributeAllList',
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateRoadAttributeAllList',
    ]),
    init () {
      this.updateRoadAttributeAllList()
    },
    // 修改
    onClickEditRoadAttribute (row) {
      this.gridRoadAttributeId = row.levelId
      this.gridRoadAttributeVisible = true
    },
    // 修改状态
    onClickStatusRoadAttribute (row) {
      if (row.isEnable == 1) {
        this.$Modal.confirm({
          title: '禁用',
          content: '确认禁用?',
          onOk: () => {
            let params = {
              id: row.levelId,
              status: 0
            }
            this.$store.dispatch('createOrEditRoadAttribute', params).then(res => {
              this.$store.dispatch('updateRoadAttributeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确认恢复?',
          onOk: () => {
            let params = {
              id: row.levelId,
              status: 1
            }
            this.$store.dispatch('createOrEditRoadAttribute', params).then(res => {
              this.$store.dispatch('updateRoadAttributeAllList', true)
            }).catch(err => {
              this.$Message.info(err.message)
            })
          }
        })
      }
    },
    onClickConfirm () {
      this.$store.dispatch('updateRoadAttributeAllList', true)
      this.$emit('onClickConfirm')
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
